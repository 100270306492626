import { useLocation } from "react-router-dom";
import Menu from './Menu.jsx'
import Container from './Container.jsx'
import Footer from './Footer.jsx'

export default function Layout() {

    const { pathname } = useLocation();

    const noRenderRoutes = ["/farechild", "/farechildform"];

    if (noRenderRoutes.includes(pathname)) {
        return null;
    }

    return (
        <>
            <Menu />
            <Container />
            <Footer />
        </>
    )
}
