import '../styles/Partners.css';
import '../styles/Header.css';
import SkunkMagazine from '../resources/image/SkunkMagazine.webp'
import PotCoin from '../resources/image/PotCoin.webp'
import PDFGroup from '../resources/image/PDFGroup.webp'
import Spliff from '../resources/image/SpliffStudiosLogo.webp'
import Farechild from '../resources/image/Farechild.webp'
import AnimatedPage from './AnimatedPage';

export default function Partners() {
    return (
        <AnimatedPage>
            <div
                id="partners"
                className='walletInfo'>

                <div className='mainHeaderContainer'>
                    <h1 className='mainHeader'>PARTNERS</h1>
                </div>

                <div id="logos">

                    <div id="up">
                        <img src={SkunkMagazine} alt="" />
                        <img src={PotCoin} alt="" />
                        <img src={PDFGroup} alt="" />
                    </div>

                    <div id="down">
                        <img id='spliffLogo' src={Spliff} alt="" />
                        <img src={Farechild} alt="" />
                    </div>

                </div>

            </div>

        </AnimatedPage>
    )
}
