import '../styles/Menu.css';
import '../styles/Hamburger.css'
import { motion } from "framer-motion"
import { useState } from 'react'
import logo from '../resources/image/LogoWhite.webp'
import { Link } from 'react-router-dom';

export default function Menu() {
    const [isOpen, setIsOpen] = useState(false)

    return (

        <>
            {/* <motion.div onClick={() => setIsOpen(!isOpen)} id="hamburger">

                <span className={`bar ${isOpen ? 'active' : ''}`}></span>
                <span className={`bar ${isOpen ? 'active' : ''}`}></span>
                <span className={`bar ${isOpen ? 'active' : ''}`}></span>

            </motion.div> */}

            <motion.div onClick={() => setIsOpen(!isOpen)} className={`${isOpen ? 'open' : ''}`} id="hamburger">
                <span className={`line ${isOpen ? 'active' : ''}`}></span>
                <span className={`line ${isOpen ? 'active' : ''}`}></span>
                <span className={`line ${isOpen ? 'active' : ''}`}></span>
            </motion.div>

            <div id="logoContainerMenu">

                <Link to="/">
                    <img src={logo} id='logo' alt="Yes Key Logo"></img>
                </Link>

            </div>

            <ul id="menu" className={`${isOpen ? 'active' : ''}`}>

                <li className='item' id='liHome'>

                    <Link to="/">HOME</Link>

                </li>

                <li className="item">

                    <Link to="/about">
                        ABOUT
                    </Link>

                </li>

                <li className="item">

                    <Link to="/features">
                        FEATURES
                    </Link>

                </li>

                <li className="item">

                    <Link to="/partners">
                        PARTNERS
                    </Link>

                </li>

                <li className="item">

                    <Link to="/farechild">
                        NFTs
                    </Link>

                </li>

                <li className="item">

                    <Link to="/contact">
                        CONTACT
                    </Link>

                </li>

            </ul>

        </>

    );
}