import '../styles/About.css';
import AboutImage from '../resources/image/About.webp'
import AnimatedPage from './AnimatedPage';

export default function About() {
    return (
        <AnimatedPage>
            <div
                id="about"
                className='walletInfo'>

                <div id="aboutText">

                    <h1>
                        ABOUT
                    </h1>

                    <p>
                        YesKey was specifically designed give users the ability to harness the true power of blockchain, all from the palm of your hand. We believe that empowering the individual extends way past the use of just Cryptocurrency and have designed this application as a tool to enhance the life of the modern sovereign individual.
                    </p>

                </div>

                <div id="aboutImage">

                    <img src={AboutImage} alt="" />

                </div>

            </div>
        </AnimatedPage>
    )
}
