import '../styles/Contact.css';
import AnimatedPage from './AnimatedPage';

export default function Contact() {
    return (
        <AnimatedPage>
            <div
                id="contact"
                className='walletInfo'>

                <div>
                    <h1>Please feel free to write to us!</h1>
                    <a href="mailto:info@yeskeywallet.com">Send Email</a>
                </div>

                {/* 
			<form action="post">

				<input type="text" placeholder='NAME' id="name" name="name" /> <br />
				<input type="text" placeholder='SUBJECT' id="subject" name="subject" /> <br />
				<input type="text" placeholder='EMAIL' id="email" name="email" /> <br />
				<input type="text" placeholder='MESSAGE' id="message" name="message" /> <br />

			</form> */}

            </div>
        </AnimatedPage>
    )
}
