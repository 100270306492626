import '../styles/Farechild.css'
import farechildLogo from '../resources/image/FarechildLogo.webp'
import farechildGoldenTicket from '../resources/image/FarechildGoldenTicket.webp'
import potCoinLogo from '../resources/image/PotCoinLogo.webp'

export default function Farechild() {
    return (
        <div id='farechild'>

            <div id='logoContainer'>
                <img id='farechildLogo' src={farechildLogo} alt="Farechild Logo" />
            </div>

            <div id='farechildGoldenTicketContainer'>
                <img id='farechildGoldenTicket' src={farechildGoldenTicket} alt="Farechild Logo" />
            </div>

            <div id="farechildText">
                <h1>
                    Farechild's Golden Ticket
                </h1>

                <p>
                    Greetings to you the lucky recipient of this Golden Ticket <br />
                    Present this ticket at the door of any Farechild produced event and receive immediate access for you and one guest. <br />
                    We look forward to creating many magical memories with you!
                </p>

                <button className='redButton'>
                    <a href="/farechildform">
                        CLICK HERE
                    </a>
                </button>

                <img id='potCoinLogo' src={potCoinLogo} alt="PotCoin Logo" />
            </div>

        </div>
    )
}
