import '../styles/Features.css';
import '../styles/Header.css';
import AnimatedPage from './AnimatedPage';

export default function Features() {
    return (
        <AnimatedPage>

            <div
                id="features"
                className='walletInfo'>

                <div className='mainHeaderContainer'>
                    <h1 className='mainHeader'>FEATURES</h1>
                </div>

                <div id="featuresContainer">

                    <div>
                        <h1>Crypto</h1>
                        <p>You are connected. Send, Receive, HODL, trade.</p>
                    </div>

                    <div>
                        <h1>NFTs</h1>
                        <p>Manage your art, collectables and one of ones.</p>
                    </div>

                    <div>
                        <h1>Memberships</h1>
                        <p>Gated access-based assets have finally arrived. Enhance your Web3 lifestyle by using YesKey to access various communities. </p>
                    </div>

                    <div>
                        <h1>Subscriptions</h1>
                        <p>From Magazines to news syndicates to IRL products. Manage it all from your wallet.</p>
                    </div>

                    <div>
                        <h1>Event tickets</h1>
                        <p>YesKey has a unique ticket verification system built into the app, that will allow you to use tokens and NFTs as gated access to various types of events and content.</p>
                    </div>


                    <div>
                        <h1>Proof of Authenticity</h1>
                        <p>
                            Fashion &  IRL Art - Revolutionized. <br />
                            Ensure that the products you purchase are real and authentic by verifying where they originated. The blockchain traces the chain of custody for any asset that has been tokenized.
                        </p>
                    </div>

                    <div>
                        <h1>NFT Collaborations</h1>
                        <p>Collaborations are the future of NFTs. Designed to keep you up to date with all the latest happenings in the NFT world. Your wallet will keep you informed about events and opportunities that are related to assets you own. </p>
                    </div>

                    <div>
                        <h1>IRL RECEIPTS</h1>
                        <p>Been there, done that, but made it Fashion. The future of Web3 will lead people down unique paths based on prior happenings. Think Re-Union shows for artists. Special Merch drops and opportunities for attendees of prior events.</p>
                    </div>

                </div>

            </div>

        </AnimatedPage>
    )
}
