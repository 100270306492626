import '../styles/Terms.css';
import AnimatedPage from './AnimatedPage';

export default function PrivacyPolicy() {
    return (
        <AnimatedPage>

            <div className='terms walletInfo'>

                <h1>
                    YesKey Privacy Policy <br />
                    Last Updated: October 27th, 2022


                </h1>

                <br />

                <p>
                    Thank you for choosing to be part of our YesKey ("YesKey," "we," "us," or "our") community. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy or our practices with regard to your personal information, please contact us at support@YesKey.io. <br></br><br />
                    Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this Privacy Policy. This Policy may change from time to time. Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the Policy periodically for updates. <br></br>
                    <br /><br />
                    OVERVIEW <br />
                    This privacy policy describes how we might use your information if you: <br />
                    Visit our website at http://www.yeskey.io <br />
                    Download and use our mobile application<br />
                    Engage with us in other related ways ― including any sales, marketing, or events<br />
                    In this privacy policy, if we refer to:<br />
                    "Website," we are referring to any website of ours that references or links to this policy<br />
                    "App," we are referring to any application of ours that references or links to this policy, including any listed above<br />
                    "Services," we are referring to our Website, App, and other related services, including any sales, marketing, or events<br />
                    By using YesKey, including downloading one of our mobile applications or visiting our website, you agree to the use, disclosure, and procedures outlined in this Privacy Policy.<br />
                    The purpose of this privacy policy is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Services immediately.<br />
                    Please read this privacy policy carefully, as it will help you understand what we do with the information that we collect.<br />
                    PERSONAL INFORMATION<br />
                    Q: What personal information do we collect?<br />
                    A:  We collect personal information that you provide to us.<br />
                    <br />
                    We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services or otherwise when you contact us.
                    <br />
                    The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect may include the following:
                    <br />
                    All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
                    <br />
                    Q: What information is automatically collected?
                    <br />
                    A: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                    <br />
                    We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                    <br />
                    Like many businesses, we also collect information through cookies and similar technologies.
                    <br />
                    The information we collect includes:<br />
                    Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).<br />
                    Device Data. We collect device data such as information about your computer, phone, tablet or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information.<br />
                    Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.<br />
                    <br />
                    Q: What information is collected through our App?<br />
                    <br />
                    A:  We collect information regarding your geolocation, mobile device, push notifications, when you use our App.<br />
                    <br />
                    If you use our App, we also collect the following information:<br />
                    <br />Geolocation Information. We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our App, to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
                    <br />Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera, storage, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
                    <br />Mobile Device Data. We automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model, Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App, we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID and information about the features of our App you accessed.
                    <br />Push Notifications. We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.
                    <br />This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.
                    <br />
                    <br />
                    <br />INFORMATION USAGE
                    <br />
                    <br />Q: How do we use your information?
                    <br />
                    <br />A: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
                    <br />
                    <br />We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
                    <br />
                    <br />We use the information we collect or receive:
                    <br />To facilitate account creation and logon process. If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.
                    <br />To post testimonials. We post testimonials on our Services that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at support@yeskey.io  and be sure to include your name, testimonial location, and contact information.
                    <br />Request feedback. We may use your information to request feedback and to contact you about your use of our Services.
                    <br />To enable user-to-user communications. We may use your information in order to enable user-to-user communications with each user's consent.
                    <br />To manage user accounts. We may use your information for the purposes of managing our account and keeping it in working order.
                    <br />     	To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.
                    <br />	To protect our Services. We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).
                    <br />	To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.
                    <br />	To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
                    <br />	Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.
                    <br />	Administer prize draws and competitions. We may use your information to administer prize draws and competitions when you elect to participate in our competitions.
                    <br />	To deliver and facilitate delivery of services to the user. We may use your information to provide you with the requested service.
                    <br />	To respond to user inquiries/offer support to users. We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.
                    <br />	To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Services, subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time.
                    <br />	Deliver targeted advertising to you. We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.
                    <br />	For other business purposes. We may use your information for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information.
                    <br />
                    <br />INFORMATION SHARING
                    <br />
                    <br />Q: Will your information be shared with anyone?
                    <br />
                    <br /> A:  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
                    <br />
                    <br />We may process or share your data that we hold based on the following legal basis:
                    <br />Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.
                    <br />Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
                    <br />Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
                    <br />Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
                    <br />Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
                    <br />More specifically, we may need to process your data or share your personal information in the following situations:
                    <br />Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                    <br />Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Place API). To find out more about Google’s Privacy Policy, please refer to this link. We obtain and store on your device ('cache') your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.
                    <br />Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
                    <br />
                    <br />
                    <br />COOKIES AND OTHER TRACKING TECHNOLOGIES
                    <br />
                    <br />Q: Do we use cookies and other tracking technologies?
                    <br />
                    <br />A:  We may use cookies and other tracking technologies to collect and store your information.
                    <br />
                    <br />We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
                    <br />
                    <br />
                    <br />Q: How long do we keep your information?
                    <br />
                    <br />A:  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.
                    <br />
                    <br />We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 2 years.
                    <br />
                    <br />When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                    <br />
                    <br />INFORMATION SAFETY
                    <br />
                    <br />Q: How do we keep your information safe?
                    <br />
                    <br /> A:  We aim to protect your personal information through a system of organizational and technical security measures.
                    <br />
                    <br /> We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                    <br />
                    <br />PRIVACY RIGHTS
                    <br />
                    <br />Q: What are your privacy rights?
                    <br />
                    <br />A:  In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
                    <br />
                    <br />In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.
                    <br />
                    <br />If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                    <br />
                    <br />If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                    <br />
                    <br />If you are a resident in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.
                    <br />
                    <br />Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/.
                    <br />
                    <br />Q: What are controls for do-not-track features?
                    <br />
                    <br />A: Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.
                    <br />
                    <br />CALIFORNIA PRIVACY RIGHTS
                    <br />
                    <br />Q: Do California residents have specific privacy rights?
                    <br />
                    <br />A:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
                    <br />
                    <br />California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                    <br />
                    <br />If you are under 18 years of age, reside in California, and have a registered account with a Service, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).
                    <br />
                    <br />CCPA Privacy policy
                    <br />
                    <br />The California Code of Regulations defines a "resident" as:
                    <br />
                    <br />(1) every individual who is in the State of California for other than a temporary or transitory purpose and
                    <br />(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose
                    <br />
                    <br />All other individuals are defined as "non-residents."
                    <br />
                    <br />If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
                    <br />
                    <br />Categories of personal information collected
                    <br />
                    <br />We have collected the following categories of personal information in the past twelve (12) months:
                    <br />Category	Examples	Collected
                    <br />A. Identifiers	Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name
                    <br />NO
                    <br />
                    <br />B. Personal information categories listed in the California Customer Records statute	Name, contact information, education, employment, employment history and financial information
                    <br />YES
                    <br />
                    <br />C. Protected classification characteristics under California or federal law	Gender and date of birth
                    <br />NO
                    <br />
                    <br />D. Commercial information	Transaction information, purchase history, financial details and payment information
                    <br />NO
                    <br />
                    <br />E. Biometric information	Fingerprints and voiceprints
                    <br />NO
                    <br />
                    <br />F. Internet or other similar network activity	Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements
                    <br />NO
                    <br />
                    <br />G. Geolocation data	Device location
                    <br />NO
                    <br />
                    <br />H. Audio, electronic, visual, thermal, olfactory, or similar information	Images and audio, video or call recordings created in connection with our business activities
                    <br />NO
                    <br />
                    <br />I. Professional or employment-related information	Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with us
                    <br />NO
                    <br />
                    <br />J. Education Information	Student records and directory information
                    <br />NO
                    <br />
                    <br />K. Inferences drawn from other personal information	Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
                    <br />NO
                    <br />
                    <br />
                    <br />
                    <br />We may also collect other personal information outside of these categories instances where you interact with us in-person, online, or by phone or mail in the context of:
                    <br />	Receiving help through our customer support channels;
                    <br />	Participation in customer surveys or contests; and
                    <br />	Facilitation in the delivery of our Services and to respond to your inquiries.
                    <br />
                    <br />Q: How do we use and share your personal information?
                    <br />
                    <br />A: More information about our data collection and sharing practices can be found in this privacy policy.
                    <br />
                    <br />You may contact us by email at support@yeskey.io, or by referring to the contact details at the bottom of this document.
                    <br />
                    <br />If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
                    <br />
                    <br />Q: Will your information be shared with anyone else?
                    <br />
                    <br />A: We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.
                    <br />
                    <br />We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal data.
                    <br />
                    <br />YesKey has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding 12 months. YesKey will not sell personal information in the future belonging to website visitors, users and other consumers.
                    <br />
                    <br />Your rights with respect to your personal data
                    <br />
                    <br />Right to request deletion of the data - Request to delete
                    <br />
                    <br />You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation or any processing that may be required to protect against illegal activities.
                    <br />
                    <br />Right to be informed - Request to know
                    <br />
                    <br />Depending on the circumstances, you have a right to know:
                    <br />	whether we collect and use your personal information;
                    <br />	the categories of personal information that we collect;
                    <br />	the purposes for which the collected personal information is used;
                    <br />                	whether we sell your personal information to third parties;
                    <br />	the categories of personal information that we sold or disclosed for a business purpose;
                    <br />	the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and
                    <br />	the business or commercial purpose for collecting or selling personal information.
                    <br />In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
                    <br />
                    <br />Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
                    <br />
                    <br />We will not discriminate against you if you exercise your privacy rights.
                    <br />
                    <br />Verification process
                    <br />
                    <br />Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
                    <br />
                    <br />We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. If, however, we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity, and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
                    <br />
                    <br />Other privacy rights
                    <br />	you may object to the processing of your personal data.
                    <br />	you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data.
                    <br />	you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
                    <br />	you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will act upon the request as soon as feasibly possible, but no later than 15 days from the date of the request submission.
                    <br />To exercise these rights, you can contact us by email at support@YesKey.io, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.
                    <br />
                    <br />Q: Do we make updates to this notice?
                    <br />
                    <br />A:  Yes, we will update this notice as necessary to stay compliant with relevant laws.
                    <br />
                    <br />We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
                    <br />
                    <br />Q: How can you contact us about this notice?
                    <br />
                    <br />A: If you have questions or comments about this notice, you may contact us by email at support@YesKey.io.
                    <br />
                    <br />Q: How can you review, update, or delete the data we collect from you?
                    <br />
                    <br />A: Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by emailing support@yeskey.io.
                </p>
            </div>

        </AnimatedPage>
    )
}
