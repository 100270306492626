import { motion as m } from 'framer-motion';

export default function AnimatedPage({ children }) {

    const animations = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
    }

    return (
        <m.div variants={animations} initial='initial' animate='animate' exit='exit' transition={{duration: 0.8}}>

            {children}

        </m.div>
    )
}