import './styles/index.css';
import { Routes, Route, useLocation } from "react-router-dom"
// import Menu from './Menu.jsx'
// import Container from './Container.jsx'
// import Footer from './Footer.jsx'
import Home from './components/Home.jsx'
import About from './components/About.jsx'
import Features from './components/Features.jsx'
import Partners from './components/Partners.jsx'
import Farechild from './components/Farechild.jsx';
import FarechildForm from './components/FarechildForm.jsx';
import Contact from './components/Contact.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import TermsOfService from './components/TermsOfService.jsx';
import Layout from './components/Layout';

import { AnimatePresence } from 'framer-motion';

function App() {

    const location = useLocation();

    return (
        <>
            <Layout />
            
            <AnimatePresence mode='wait'>

                <Routes key={location.pathname} location={location}>

                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/features" element={<Features />} />
                    <Route path="/partners" element={<Partners />} />

                    <Route path="/farechild" element={<Farechild />} />
                    <Route path="/farechildform" element={<FarechildForm />} />
                    
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/termsofservice" element={<TermsOfService />} />

                </Routes>

            </AnimatePresence>
        </>
    );
}

export default App;
