import { Link } from 'react-router-dom';
import '../styles/Footer.css'
import { FaTwitter } from 'react-icons/fa';

export default function Footer() {
    return (
        <div id='footer'>
            
            <Link to="/termsofservice">TERMS OF SERVICE</Link>
            <Link to="https://twitter.com/yeskeylabs"><FaTwitter id='twitter'/></Link>
            <Link to="/privacypolicy">PRIVACY POLICY</Link>
            
        </div>
    )
}
