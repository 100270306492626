import '../styles/FarechildForm.css'
import farechildLogo from '../resources/image/FarechildLogo.webp'
import farechildGoldenTicket from '../resources/image/FarechildGoldenTicket.webp'
import farechildAppStore from '../resources/image/FarechildAppStore.webp'
import farechildGooglePlay from '../resources/image/FarechildGooglePlay.webp'
import Step1 from '../resources/image/Step1.webp'
import Step2 from '../resources/image/Step2.webp'
import Step3 from '../resources/image/Step3.webp'
import step2img from '../resources/image/Step2Img.webp'
import FarechildXPotCoinLogo from '../resources/image/FarechildXPotCoinLogo.webp'


export default function FarechildForm() {
    return (
        <div id='farechildForm'>

            <div id="step1">

                <div id='logoContainerForm'>
                    <img id='farechildFormLogo' src={farechildLogo} alt="Farechild Logo" />
                </div>

                <div id='goldenTicketContainerForm'>
                    <img id='farechildFormGoldenTicket' src={farechildGoldenTicket} alt="Farechild Logo" />
                </div>

                <div id="textFormContainer">

                    <div id='step1ImgContainer'>
                        <img id='step1img' src={Step1} alt="" />
                    </div>

                    <div id="step1Text">

                        <p>
                            INSTALL APP <br />
                            Download and install the Yeskey™ app on your mobile device.
                        </p>

                        <div className="apps">

                            <a href="https://apps.apple.com/us/app/yeskey-wallet/id6444247760">
                                <img src={farechildAppStore} alt="" />
                            </a>

                            <a href="https://play.google.com/store/apps/details?id=com.yeskeywallet">
                                <img src={farechildGooglePlay} alt="" />
                            </a>

                        </div>

                    </div>

                </div>

            </div>

            <div id="step2">

                <div id='step2ImgContainer'>
                    <img id='step2img' src={Step2} alt="" />
                </div>

                <div id="step2Text">

                    <p>
                        GETTING YOUR ETHEREUM RECEIVING ADDRESS <br /> <br />
                        In order to receive your Farechild Golden Ticket, we will need your Ethereum receiving address from the Yeskey™ app. <br />
                        Please See Video Instructions:
                    </p>


                    <div id="instructions">
                        <iframe src="https://www.youtube.com/embed/rqqtKnB2314" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>

                </div>

            </div>

            <div id="step3">

                <div id="textForm3">

                    <div id='step3ImgContainer'>
                        <img src={Step3} alt="" />
                    </div>

                    <div id="step3Text">

                        <p>
                            SUBMITTING YOUR FARECHILD CONFIRMATION NUMBER <br />
                        </p>

                        <p id='grey'>
                            Please submit the required information below:
                        </p>

                        {/* <form action="post">

                            <input type="text" placeholder='NAME' id="name" name="name" /> <br />
                            <input type="text" placeholder='EMAIL' id="email" name="email" /> <br />
                            <input type="text" placeholder='PHONE NUMBER' id="number" name="number" /> <br />
                            <input type="text" placeholder='PASTE YOUR ETHEREUM ADDRESS' id="ethaddress" name="ethaddress" /> <br />
                            <input type="text" placeholder='FARECHILD CONFIRMATION CODE' id="code" name="code" /> <br />

                        </form> */}

                        <div>

                            {/* <p>
                                Please send: <br />
                            </p> */}
                                <ul id='listFarechild'>
                                    <li>NAME</li>
                                    <li>PHONE NUMBER</li>
                                    <li>ETHEREUM ADDRESS</li>
                                    <li>FARECHILD CONFIRMATION CODE</li>
                                </ul>
                                <br />
                                <a id='sendEmailFarechild' href="mailto:info@yeskeywallet.com">Contact us</a>
                           

                            {/* <button className='redButtonForm'>
                                SUBMIT
                            </button> */}

                        </div>

                    </div>

                </div>

                <div id="white">

                    {/* <h2>
                        CONTACT
                    </h2>

                    <h2>
                        FAQ
                    </h2>

                    <h2>
                        SUPPORT
                    </h2> */}

                </div>

                <div id='red'>

                    <img src={FarechildXPotCoinLogo} alt="" />

                </div>

            </div>

        </div>
    )
}
