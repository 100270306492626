import '../styles/Container.css';

export default function Container() {

    return (
        <>
            <div id="box"></div>
        </>
    )
}
